import {addChildRoute, addRoute, addRoutes, redirectRouteToBackend, removeRouteByName} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import DossierListingPage from "@pazion/pazion-core/src/views/dossier/DossierListingPage.vue"

addRoute(  {
    path: '/',
    name: 'Home',
    component: DossierListingPage,
    meta: {
        'show-in-nav': false,
        roles: ['user'],
        sort: -100
    }
});

removeRouteByName("queue");

